import { EventDispatcher } from 'three';
const WORLD_CHAT_URL = 'wss://mvgtvcmfpi.execute-api.ap-northeast-1.amazonaws.com/production?access_token=Bearer ';

class WorldChatSocket extends EventDispatcher {
    constructor() {
        super();
        this.socket = null;
    }

    init(token) {
        this.socket = new WebSocket(WORLD_CHAT_URL + token);
        this.socket.onopen = () => {
            console.log('Socket opened');
        };

        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            this.dispatchEvent({ type: 'message', data });
            console.log('Received message:', data);
        };

        this.socket.onclose = () => {
            console.log('Socket closed');
        };
    }

    send(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.log('Socket is not open');
        }
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }

    open() {
        if (this.socket && this.socket.readyState === WebSocket.CLOSED) {
            this.socket = new WebSocket(this.socket.url);
            console.log('Socket opened');
        } else {
            console.log('Socket is already open');
        }
    }
}

export default WorldChatSocket;
