import ky from 'ky';

const httpClient = ky.create({
  prefixUrl: process.env.NODE_ENV === 'development'
    ? '/api'
    : 'https://q4bqek3el5.execute-api.ap-northeast-1.amazonaws.com',
  throwHttpErrors: false,
  timeout: 30000,
  hooks: {
    beforeRequest: [
      request => {
        const token = window.localStorage.getItem('token');
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        }
      }
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status === 401) {
          // TODO
          return;
        }

        if (response.status === 403) {
          // TODO
          return;
        }

        return response;
      },
    ],
  },
});

export default httpClient;
