import { defineStore } from 'pinia';
import router from '@/router';
import { loginByEmail, getUserInfo, updateUserInfo, clearUserInfo } from '@/services/user';
import { useWorldChatStore } from '@/stores/worldChat';

export const useUserStore = defineStore({
  id: 'user',

  state: () => ({
    profile: null,
    token: window.localStorage.getItem('token') ?? ''
  }),

  getters: {
    getGender: (state) => {
      return state.profile?.gender || 'male'
    },

    isAuthenticated: state => {
      try {
        if (state.token) {
          const arr = state.token.split('.');
          if (arr[1]) {
            const { exp } = JSON.parse(atob(arr[1]));
            return Date.now() < exp * 1000;
          }
        }
      } catch (_) { }

      return false;
    },
    isRegistered: state => state.profile
      ? !!state.profile.nickname && !!state.profile.gender && !!state.profile.role
      : false,
  },

  actions: {
    async logout() {
      await clearUserInfo();
      this.$patch({ token: '' });
      router.push('/login');
    },

    async loginByEmail(email, password, keepLogin = false) {
      const worldChatStore = useWorldChatStore();
        // bussinessCode
          const res = await loginByEmail(email, password, keepLogin);
          if(res.businessCode === 0){
            this.$patch({ token: res.data.accessToken});
            await this.getUserInfo();
            worldChatStore.init(res.data.accessToken);
            if (!this.isRegistered) {
              router.push('/signup');
            } else {
              router.push('/');
            }
          } else {
            const errMsg = res?.businessCode?.toString()
            throw new Error(errMsg)
          }
    },

    async getUserInfo() {
      try {
        const { data: profile } = await getUserInfo();
        this.$patch({ profile });
        return profile;
      } catch (error) {
        
      }
    },

    async updateUserInfo(profile) {
      await updateUserInfo({
        userInfo: { ...this.profile, ...profile },
        updateMask: {paths :Object.keys(profile)}
      });
      this.$patch({ profile });
    },
  },
})
