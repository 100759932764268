<template>
  <!-- <router-view /> -->
  <router-view v-slot="{ Component, route }">
   <transition :name="route.meta.transition || 'fade'" appear>
      <keep-alive>
        <n-message-provider>
           <n-notification-provider container-class="were-notification" placement="top">
             <component :is="Component" :key="route.path" />
           </n-notification-provider>
        </n-message-provider>
      </keep-alive>
    </transition>
  </router-view>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import {NNotificationProvider} from 'naive-ui'

onBeforeMount(async () => {
  const userStore = useUserStore();

  if (userStore.isAuthenticated) {
    await userStore.getUserInfo();
  }

  if (!userStore.isRegistered) {
    // return router.push('/signup');
  }

  // router.push('/');
});
</script>

<style lang="less">
@import url('@/assets/css/override.less');

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  background-color: #000;
  transform: scale(0.9);
}


</style>