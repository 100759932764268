export default function TokenPiniaPlugin({ store }) {
  if (store.$id === 'user') {
    store.$subscribe(({ payload }) => {
      if ('token' in payload) {
        if (payload.token) {
          window.localStorage.setItem('token', payload.token);
        } else {
          window.localStorage.removeItem('token');
        }
      }
    });
  }
}
