export function stringToColor(str) {
    // 哈希函数，用于将字符串转换为数字
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // 将数字转换为颜色代码
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
}

export function isApproximatelyEqual(value1, value2, tolerance) {
    return Math.abs(value1 - value2) <= tolerance;
}

export function getCurrentTime(timestamp) {
    const now = timestamp ? new Date(timestamp) : new Date(); // 获取当前时间
    let hours = now.getHours(); // 获取小时数
    let minutes = now.getMinutes(); // 获取分钟数
    
    let timeSystem = '';

    if(hours > 12) {
        hours = hours - 12;
        timeSystem = 'pm'
    } else {
        hours = hours < 10 ? '0' + hours : hours;
        timeSystem = 'am'
    }
    // 如果小时或分钟小于10，则在前面添加一个'0'以保持两位数
    minutes = minutes < 10 ? '0' + minutes : minutes;
  
    return hours + ':' + minutes + ' ' + timeSystem; // 返回格式化的时间字符串
  }

// 深拷贝
export function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') return obj;
    const copy = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            copy[key] = deepCopy(obj[key]);
        }
    }
    return copy;
}