import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/stores/user";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/register.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/signup.vue"),
  },
  {
    path: "/model-generation",
    name: "ModelGeneration",
    component: () => import("@/views/model-generation.vue"),
  },
  {
    path: "/solo-room",
    name: "SoloRoom",
    component: () => import("@/views/solo-room.vue"),
    meta: { transition: 'scale' },
  },
  {
    path: "/group-room",
    name: "GroupRoom",
    component: () => import("@/views/group-room.vue"),
    meta: { transition: 'scale' },
  },
  {
    path: "/social-space",
    name: "SocialSpace",
    component: () => import("@/views/social-space.vue"),
    meta: { transition: 'scale' },
  },
  {
    path: "/social-space",
    name: "SocialSpace",
    component: () => import("@/views/social-space.vue"),
    meta: { transition: 'scale' },
  },
  {
    path: "/opt",
    name: "OptWorldMenu",
    component: () => import("@/views/opt.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const userStore = useUserStore();
  console.log(userStore.isAuthenticated,'-userStore')
  if (!userStore.isAuthenticated && to.path !== "/login" && to.path !== '/register') {
    next("/login");
  } else if (!to.matched.length) {
    next("/");
  } else {
    next();
  }
});

export default router;
