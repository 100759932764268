import { defineStore } from 'pinia';
import WorldChatSocket from '@/utils/WorldChatSocket';
import { stringToColor, getCurrentTime } from '@/utils/common';

const worldChatSocket = new WorldChatSocket();

export const useWorldChatStore = defineStore('worldChat', {
    state: () => ({
        messages: [
            // { email: "2345@def.com", message: "Hello", color: "#000000",isSelf: false },
        ],
    }),
    actions: {
        updateMessages(messages) {
            messages.forEach((messageItem) => {
                messageItem.color = stringToColor(messageItem.email);
                messageItem.time = getCurrentTime();
            });
            this.messages.push(...messages);
        },
        init(token) {
            worldChatSocket.close();
            worldChatSocket.init(token);
        },
        close() {
            worldChatSocket.close();
        },
        sendMessage(messageItem) {
            messageItem.color = stringToColor(messageItem.email);
            messageItem.time = getCurrentTime();
            this.messages.push(messageItem);
            worldChatSocket.send({ action: "sendMessage", message: messageItem.message });
        },
    },
});


worldChatSocket.init(localStorage.getItem('token'));

worldChatSocket.addEventListener('message', ({data}) => {
    const worldChatStore = useWorldChatStore();
    worldChatStore.updateMessages([data]);
});
