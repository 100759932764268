import httpClient from '@/utils/http-client';

export function loginByEmail(email, password, keepLogin) {
  return httpClient.post('login', {
  // return httpClient.post('userinfo-login', {
    json: { email, password, keepLogin },
  }).json();
}

// { nickname, gender, role }
export function updateUserInfo(json) {
  // return httpClient.post('userinfo-update', { json }).json();
  return httpClient.post('updateUserInfo', { json }).json();
}

export function getUserInfo() {
  return httpClient.get('userinfo-get').json();
}

export function clearUserInfo() {
  return httpClient.post('userinfo-clear').json();
}

// 获取验证码
export function getVerifyCode(email, password) {
  return httpClient.post('sendRegisterEmail', {
    json: { email, password },
  }).json();
}

// 验证码校验
export function registerByEmail(email, password, emailCode) {
  return httpClient.post('register', {
    json: { email, password, emailCode }
  }).json();
}

// 登录 
export function loginApi2(email, password, keepLogin) {
  return httpClient.post('login', {
    json: { email, password, keepLogin }
  }).json();
}