import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from "@lingui/core"
import { plugins } from './stores/plugins';
import App from './App.vue';
import router from './router';
import { getDefaultLocale } from './utils/i18n';
import './registerServiceWorker';
import './index.css';

i18n.load({
  en: require('./locales/en/messages').messages,
  zh: require('./locales/zh/messages').messages,
});
i18n.activate(getDefaultLocale());

// import {connect, disconnect, emitMessage, sendMessage} from "@/util/awsSocket";

// const ws = new WebSocket('wss://tuosxvxn91.execute-api.ap-southeast-1.amazonaws.com/dev')
const app = createApp(App);
const pinia = createPinia();
plugins.forEach(x => pinia.use(x));
// app.provide('awsSocket', { connect, disconnect, sendMessage, emitMessage })
// app.config.globalProperties.$socket = ws

app.use(pinia).use(router).mount('#app');
